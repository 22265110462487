import React, { useState, useEffect } from 'react';
import { LiveboardEmbed, AuthStatus, Action } from "@thoughtspot/visual-embed-sdk";

export const Liveboard = (props) => {

  useEffect(() => {

      const liveboardEmbed = new LiveboardEmbed("#tle", {
        frameParams: {
            width: '100%',
            height: '100%',
        },
        //visibleActions: [Action.CopyLink, Action.DownloadAsCsv],
        hiddenActions: [Action.ShowUnderlyingData, Action.MakeACopy, Action.Share, Action.TML,Action.CreateMonitor,Action.ManageMonitor,Action.Schedule,Action.SchedulesList,Action.SpotIQAnalyze,Action.SyncToSheets,Action.SyncToOtherApps,Action.SyncToSlack,Action.SyncToTeams,Action.ManagePipelines,Action.ExportTML],
        fullHeight: true,
        defaultHeight: 1000,
        liveboardId: props.liveboardId,
        enableVizTransformations: true,
        preventLiveboardFilterRemoval: true,
      });
      liveboardEmbed.render();
  }, [props.liveboardId]);
  return <div id="tle" />;
};
    
