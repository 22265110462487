import React from "react";

import {useState} from 'react';

export const SideBar = (props) => {

  const [isCampaignsActive, setIsCampaignsActive] = useState(false);
  const [isRewardsActive, setIsRewardsActive] = useState(false);
  const [isToolsActive, setIsToolsActive] = useState(false);

  const handleRewardsClick = event => {
    // 👇️ toggle isActive state on click
    setIsRewardsActive(currentRewards => !currentRewards);
  };

  const handleCampaignsClick = event => {
    // 👇️ toggle isActive state on click
    setIsCampaignsActive(currentCampaigns => !currentCampaigns);
  };

  const handleToolsClick = event => {
    // 👇️ toggle isActive state on click
    setIsToolsActive(currentTools => !currentTools);
  };

  const logoStyle={
    backgroundImage: "url(assets/logo/platform_blue.png)",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50%',
  };

  return <div tag="menu-sidebar" responsive="" className={ `menu-sidebar left ${props.sidebarCollapsed ? 'compacted' : 'expanded'}` }>
    <div className="main-container main-container-fixed">
      <div className="scrollable">
        <div className="menu">
          <ul className="menu-items">
            <li className="menu-item">
              <a className="active" title="Home" href="/pages/home">
                <div className="menu-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-home-outline" fill="currentColor">
                    <g data-name="Layer 2">
                      <g data-name="home">
                        <rect width="24" height="24" opacity="0"></rect>
                        <path d="M20.42 10.18L12.71 2.3a1 1 0 0 0-1.42 0l-7.71 7.89A2 2 0 0 0 3 11.62V20a2 2 0 0 0 1.89 2h14.22A2 2 0 0 0 21 20v-8.38a2.07 2.07 0 0 0-.58-1.44zM10 20v-6h4v6zm9 0h-3v-7a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v7H5v-8.42l7-7.15 7 7.19z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="menu-title">Home</span>
              </a>
            </li>
            <li className="menu-item">
              <a href="#" className={ `${isRewardsActive ? 'collapsed' : 'expanded'}` } title="Reward Portfolio" onClick={handleRewardsClick}>
                <div className="menu-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-gift-outline" fill="currentColor">
                    <g data-name="Layer 2">
                      <g data-name="gift">
                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                        <path d="M19.2 7h-.39A3 3 0 0 0 19 6a3.08 3.08 0 0 0-3.14-3A4.46 4.46 0 0 0 12 5.4 4.46 4.46 0 0 0 8.14 3 3.08 3.08 0 0 0 5 6a3 3 0 0 0 .19 1H4.8A2 2 0 0 0 3 9.2v3.6A2.08 2.08 0 0 0 4.5 15v4.37A1.75 1.75 0 0 0 6.31 21h11.38a1.75 1.75 0 0 0 1.81-1.67V15a2.08 2.08 0 0 0 1.5-2.2V9.2A2 2 0 0 0 19.2 7zM19 9.2v3.6a.56.56 0 0 1 0 .2h-6V9h6a.56.56 0 0 1 0 .2zM15.86 5A1.08 1.08 0 0 1 17 6a1.08 1.08 0 0 1-1.14 1H13.4a2.93 2.93 0 0 1 2.46-2zM7 6a1.08 1.08 0 0 1 1.14-1 2.93 2.93 0 0 1 2.45 2H8.14A1.08 1.08 0 0 1 7 6zM5 9.2A.56.56 0 0 1 5 9h6v4H5a.56.56 0 0 1 0-.2zM6.5 15H11v4H6.5zm6.5 4v-4h4.5v4z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="menu-title">Reward Portfolio</span>
                <div pack="nebular-essentials" className="chevron-icon expand-state">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24">
                    <g data-name="Layer 2">
                      <g data-name="chevron-down">
                        <rect width="24" height="24" opacity="0"></rect>
                        <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </a>
              <ul className={ `menu-items ${isRewardsActive ? 'collapsed' : 'expanded'}` }>
                <li className="menu-item">
                  <a className="" title="Partners" href="/pages/partners">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-layers-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="layers">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                            <path d="M21 11.35a1 1 0 0 0-.61-.86l-2.15-.92 2.26-1.3a1 1 0 0 0 .5-.92 1 1 0 0 0-.61-.86l-8-3.41a1 1 0 0 0-.78 0l-8 3.41a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l2.26 1.3-2.15.92a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l2.26 1.3-2.15.92a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l8 4.6a1 1 0 0 0 1 0l8-4.6a1 1 0 0 0 .5-.92 1 1 0 0 0-.61-.86l-2.15-.92 2.26-1.3a1 1 0 0 0 .5-.92zm-9-6.26l5.76 2.45L12 10.85 6.24 7.54zm-.5 7.78a1 1 0 0 0 1 0l3.57-2 1.69.72L12 14.85l-5.76-3.31 1.69-.72zm6.26 2.67L12 18.85l-5.76-3.31 1.69-.72 3.57 2.05a1 1 0 0 0 1 0l3.57-2.05z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title ng-tns-c100-2">Partners</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a className="" title="Rewards" href="/pages/rewards">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-gift-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="gift">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                            <path d="M19.2 7h-.39A3 3 0 0 0 19 6a3.08 3.08 0 0 0-3.14-3A4.46 4.46 0 0 0 12 5.4 4.46 4.46 0 0 0 8.14 3 3.08 3.08 0 0 0 5 6a3 3 0 0 0 .19 1H4.8A2 2 0 0 0 3 9.2v3.6A2.08 2.08 0 0 0 4.5 15v4.37A1.75 1.75 0 0 0 6.31 21h11.38a1.75 1.75 0 0 0 1.81-1.67V15a2.08 2.08 0 0 0 1.5-2.2V9.2A2 2 0 0 0 19.2 7zM19 9.2v3.6a.56.56 0 0 1 0 .2h-6V9h6a.56.56 0 0 1 0 .2zM15.86 5A1.08 1.08 0 0 1 17 6a1.08 1.08 0 0 1-1.14 1H13.4a2.93 2.93 0 0 1 2.46-2zM7 6a1.08 1.08 0 0 1 1.14-1 2.93 2.93 0 0 1 2.45 2H8.14A1.08 1.08 0 0 1 7 6zM5 9.2A.56.56 0 0 1 5 9h6v4H5a.56.56 0 0 1 0-.2zM6.5 15H11v4H6.5zm6.5 4v-4h4.5v4z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title">Rewards</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a className="" title="Partner Codes" href="/pages/partner-codes">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-layers-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="layers">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                            <path d="M21 11.35a1 1 0 0 0-.61-.86l-2.15-.92 2.26-1.3a1 1 0 0 0 .5-.92 1 1 0 0 0-.61-.86l-8-3.41a1 1 0 0 0-.78 0l-8 3.41a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l2.26 1.3-2.15.92a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l2.26 1.3-2.15.92a1 1 0 0 0-.61.86 1 1 0 0 0 .5.92l8 4.6a1 1 0 0 0 1 0l8-4.6a1 1 0 0 0 .5-.92 1 1 0 0 0-.61-.86l-2.15-.92 2.26-1.3a1 1 0 0 0 .5-.92zm-9-6.26l5.76 2.45L12 10.85 6.24 7.54zm-.5 7.78a1 1 0 0 0 1 0l3.57-2 1.69.72L12 14.85l-5.76-3.31 1.69-.72zm6.26 2.67L12 18.85l-5.76-3.31 1.69-.72 3.57 2.05a1 1 0 0 0 1 0l3.57-2.05z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title">Partner Codes</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a className="" title="Tags" href="/pages/tags">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-grid-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="grid">
                            <rect width="24" height="24" opacity="0"></rect>
                            <path d="M9 3H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zM5 9V5h4v4z"></path>
                            <path d="M19 3h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm-4 6V5h4v4z"></path>
                            <path d="M9 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"></path>
                            <path d="M19 13h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm-4 6v-4h4v4z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title">Tags</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a className="" title="Categories" href="/pages/categories">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-list-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="list">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                            <circle cx="4" cy="7" r="1"></circle>
                            <circle cx="4" cy="12" r="1"></circle>
                            <circle cx="4" cy="17" r="1"></circle>
                            <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                            <rect x="7" y="16" width="14" height="2" rx=".94" ry=".94"></rect>
                            <rect x="7" y="6" width="14" height="2" rx=".94" ry=".94"></rect>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title ng-tns-c100-6">Categories</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <a href="#" className={ `${isCampaignsActive ? 'collapsed' : 'expanded'}` } title="Campaign Websites" onClick={handleCampaignsClick}>
                <div className="menu-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-browser-outline" fill="currentColor">
                    <g data-name="Layer 2">
                      <g data-name="browser">
                        <rect width="24" height="24" opacity="0"></rect>
                        <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zm1 15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7h14zM5 9V6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3z"></path>
                        <circle cx="8" cy="7.03" r="1"></circle>
                        <circle cx="12" cy="7.03" r="1"></circle>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="menu-title">Campaign Websites</span>
                <div pack="nebular-essentials" className="chevron-icon expand-state">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24">
                    <g data-name="Layer 2">
                      <g data-name="chevron-down">
                        <rect width="24" height="24" opacity="0"></rect>
                        <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </a>
              <ul className={ `menu-items ${isCampaignsActive ? 'collapsed' : 'expanded'}` }>
                <li className="menu-item">
                  <a className="" title="Websites" href="/pages/websites">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-list-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="list">
                            <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                            <circle cx="4" cy="7" r="1"></circle>
                            <circle cx="4" cy="12" r="1"></circle>
                            <circle cx="4" cy="17" r="1"></circle>
                            <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                            <rect x="7" y="16" width="14" height="2" rx=".94" ry=".94"></rect>
                            <rect x="7" y="6" width="14" height="2" rx=".94" ry=".94"></rect>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title ng-tns-c100-8">Websites</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="menu-item">
              <a href="#" className={ `${isToolsActive ? 'collapsed' : 'expanded'}` } title="Tools" onClick={handleToolsClick}>
                <div className="menu-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-bulb-outline" fill="currentColor">
                    <g data-name="Layer 2">
                      <g data-name="bulb">
                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                        <path d="M12 7a5 5 0 0 0-3 9v4a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-4a5 5 0 0 0-3-9zm1.5 7.59a1 1 0 0 0-.5.87V20h-2v-4.54a1 1 0 0 0-.5-.87A3 3 0 0 1 9 12a3 3 0 0 1 6 0 3 3 0 0 1-1.5 2.59z"></path>
                        <path d="M12 6a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1z"></path>
                        <path d="M21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"></path>
                        <path d="M5 11H3a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"></path>
                        <path d="M7.66 6.42L6.22 5a1 1 0 0 0-1.39 1.47l1.44 1.39a1 1 0 0 0 .73.28 1 1 0 0 0 .72-.31 1 1 0 0 0-.06-1.41z"></path>
                        <path d="M19.19 5.05a1 1 0 0 0-1.41 0l-1.44 1.37a1 1 0 0 0 0 1.41 1 1 0 0 0 .72.31 1 1 0 0 0 .69-.28l1.44-1.39a1 1 0 0 0 0-1.42z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="menu-title">Tools</span>
                <div pack="nebular-essentials" className="chevron-icon expand-state">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24">
                    <g data-name="Layer 2">
                      <g data-name="chevron-down">
                        <rect width="24" height="24" opacity="0"></rect>
                        <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </a>
              <ul className={ `menu-items ${isToolsActive ? 'collapsed' : 'expanded'}` }>
                <li className="menu-item">
                  <a className="" title="Code Generator" href="/pages/code-generator">
                    <div className="menu-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-person-add-outline" fill="currentColor">
                        <g data-name="Layer 2">
                          <g data-name="person-add">
                            <rect width="24" height="24" opacity="0"></rect>
                            <path d="M21 6h-1V5a1 1 0 0 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0V8h1a1 1 0 0 0 0-2z"></path>
                            <path d="M10 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"></path>
                            <path d="M10 13a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="menu-title">Code Generator</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="logo"></div>
      </div>
    </div>
  </div>
};