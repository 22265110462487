import { Switch, Route, useHistory } from "react-router-dom";
// Material-UI imports

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

// Sample app imports
import { init, AuthType, logout, AuthStatus } from "@thoughtspot/visual-embed-sdk";

import { PageLayout } from "./ui-components/PageLayout";

const embedConfig = {
  thoughtSpotHost: "https://tlcmarketing-prod.thoughtspot.cloud",
  authType: AuthType.EmbeddedSSO,
  //customCssUrl: "cdn.jsdelivr.net/gh/robereng/override@main/override.css",
  ignoreNoCookieAccess: true,
  disableLoginFailurePage: true
}

let authStatus = init(embedConfig);
authStatus.on(AuthStatus.FAILURE, (reason) => {
  console.log('Authentication failed');
  console.log(reason);
  logout();
});
authStatus.on(AuthStatus.SUCCESS, () => {
  console.log('Authentication success');
});

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout authStatus={authStatus}>
      </PageLayout>
    </MsalProvider>
  );
}

export default App;
