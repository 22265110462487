import { 
  AuthenticatedTemplate, 
  UnauthenticatedTemplate, 
  useIsAuthenticated, 
  useMsal,
} from "@azure/msal-react";
import { NavBar } from "./NavBar";
import { SideBar } from "./SideBar";
import {useState, useEffect} from 'react';
import { AuthStatus } from "@thoughtspot/visual-embed-sdk";
import { Search } from "./Search";
import { Liveboard } from "./Liveboard";
import { loginRequest } from "../authConfig";
import Select from 'react-select'
export const PageLayout = (props) => {

  console.log('page layout', props.authStatus);

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const [options, setOptions] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);
  const [liveboardId, setliveboardId] = useState("liveboard");
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  
  function handleSideBarCollapse(evt) {
    setSidebarCollapsed(!sidebarCollapsed);
  }

  const handleLogin = () => {
    setAnchorEl(null);
    instance.loginPopup();
  }


  useEffect(() => {

    if (isAuthenticated) {

      if(!options)
      {
        const url = 'https://tlcmarketing-prod.thoughtspot.cloud/api/rest/2.0/metadata/search'
        fetch(url, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "X-Requested-By": "ThoughtSpot",
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({  "metadata": [{"type": "LIVEBOARD"}], "tag_identifiers": ["Internal Report","External Report"]})
        })
        .then(response =>  {
          if(response.ok) {
            return response.json();
          }
          throw response;
        })
        .then(data => {
          if(data.length > 0) {
            const optionArray = data.map((liveboard) => ({ 'value': liveboard.metadata_id, 'label': liveboard.metadata_name}));
            setOptions(optionArray);
            setCurrentOption(optionArray[0]);
            setliveboardId(optionArray[0].value);
          }

          else { 
            setliveboardId("f49723af-df07-4007-8907-2a59f2d9bdb5");
          }
        })
        .catch(response => {
          setliveboardId("f49723af-df07-4007-8907-2a59f2d9bdb5");
        })
      }
    }
  }, [isAuthenticated])

  const logoStyle={
      backgroundImage: "url(https://euz-cosmosccp-prd-app.azurewebsites.net/assets/logo/cosmos-logo-black.svg)",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
  };

  function handleOnSelectChange(evt, action) {
    setliveboardId(evt.value);
    setCurrentOption(evt);
  }

  return (
    <>
      <div className="App">
        <div className="layout-header fixed">
          <NavBar onHamburgerClick={handleSideBarCollapse} />
        </div>
        <div className="layout-container">
          <SideBar sidebarCollapsed={sidebarCollapsed} />
          <div className="content">
            <AuthenticatedTemplate>
              <Select 
                value={currentOption} 
                onChange={handleOnSelectChange} 
                options={options} 
                isSearchable={false}
                styles={{
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '.9375rem',
                    fontWeight: 600,
                    width: '400px',
                    paddingLeft: '25px'
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#3366ff',
                    backgroundColor: '#f7f9fc',
                  }),
                }} 
              />
              <Liveboard liveboardId={liveboardId} />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <div className="unauthenticated-block">
                <div className="center-logo" style={logoStyle}></div>
                <div className="sign-in-message">Please <a href="#" onClick={() => handleLogin()}>sign-in</a> to see your campaign data.</div>
              </div>
            </UnauthenticatedTemplate>
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
};