import React from "react";
import {useState, useRef, useEffect} from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import WelcomeName from "./WelcomeName";

export const NavBar = (props) => {

  const [isContextMenuOpen, setContextMenuOpen] = useState(false);

  const handleUserClick = event => {
    // 👇️ toggle isActive state on click
    setContextMenuOpen(currentRewards => !currentRewards);
  };

  const myRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
        setContextMenuOpen(false);
    }
  };

  const { instance } = useMsal();
  let isLoggedIn = instance.getAllAccounts().length > 0;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogin = () => {
    setAnchorEl(null);
    instance.loginPopup(loginRequest);
  }

  const handleLogout = (logoutType) => {
    setAnchorEl(null);
    instance.logoutPopup();
    instance.setActiveAccount(null);
  }

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const logoStyle={
    backgroundImage: "url(https://euz-cosmosccp-prd-app.azurewebsites.net/assets/logo/cosmos-insights.png)",
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    };

  const userPictureStyle={
  backgroundImage: "url(https://euz-cosmosccp-prd-app.azurewebsites.net/assets/images/default-user.png)",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  };

  return <nav className="fixed">
    <div className="layout-header-container">
      <div className="header-container">
        <div className="logo-container">
          <a href="#" className="sidebar-toggle" onClick={props.onHamburgerClick}>
            <div className="sidebar-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-menu-2-outline" fill="currentColor">
                <g data-name="Layer 2">
                  <g data-name="menu-2">
                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                    <circle cx="4" cy="12" r="1"></circle>
                    <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                    <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect>
                    <rect x="3" y="6" width="18" height="2" rx=".94" ry=".94"></rect>
                  </g>
                </g>
              </svg>
            </div>
          </a>
          <a href="#" className="logo" style={logoStyle}></a>
        </div>
      </div>
      <div className="header-container">
        <div className="width220">
          <button type="button" className="select-button">HQ
            <div className="select-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                  <g data-name="chevron-down">
                    <rect width="24" height="24" opacity="0"></rect>
                    <path d="M12 15.5a1 1 0 0 1-.71-.29l-4-4a1 1 0 1 1 1.42-1.42L12 13.1l3.3-3.18a1 1 0 1 1 1.38 1.44l-4 3.86a1 1 0 0 1-.68.28z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div className="user-container" ref={myRef} onClick={handleUserClick}>
          <div className="user-picture image" style={userPictureStyle}></div>
          <div className="info-container">
            <WelcomeName />
          </div>
          <div className={ `context-menu ${isContextMenuOpen ? 'open' : 'closed'}` }>
            <ul className="menu-items">
              <li className="menu-item">
                <a className={ `${isLoggedIn ? 'disabled' : 'enabled'}` } title="Login" onClick={() => handleLogin()}>
                  <span className="menu-title">Login</span>
                </a>
              </li>
              <li className="menu-item">
                <a className={ `${isLoggedIn ? 'enabled' : 'disabled'}` } title="Log out" onClick={() => handleLogout()}>
                  <span className="menu-title">Log out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
};